import { useEffect, useState } from "react";
import { ColorOverlayFilter } from "@pixi/filter-color-overlay";
import { Graphics, Sprite } from "@pixi/react";
import { WorkplaceRect, WorkplaceRectForColleague } from "./SpriteIndex";
import BoundingBox from "../../../Domain/Types/BoundingBox.type";
import { PlaceVariant } from "../../../Domain/Types/FloorPlan/PlaceVariant.type";
import { ToolSelection } from "../../Views/CreateFloorPlanView/CreateFloorPlanView";
import { variantCircle } from "./PlaceSprite.functions";

interface Props {
  boundingBox: BoundingBox;
  filterGroup: ColorOverlayFilter[];
  variantColor: number;
  variant: PlaceVariant;
  placeScale: number;
  isSelectable: boolean;
  tool?: ToolSelection;
  isColleaguesPlace?: boolean;
}

/**
 * draw the workplace sprite by pixi-sprite or pixi-graphic according to module(booking/creating)
 * @version 1.0.0
 */
export function WorkPlaceSprite({
  boundingBox,
  filterGroup,
  variantColor,
  variant,
  placeScale,
  isSelectable,
  tool,
  isColleaguesPlace
}: Props) {
  const { width = 160, height = 80 } = boundingBox;

  const [circleRadius, setCircleRadius] = useState(width * placeScale * 0.2);
  const [lineWidth, setLineWidth] = useState(width * placeScale * 0.04);

  const circleSize = (width * placeScale) / 2.1;
  const circlePos = 0 - circleSize / 2;

  useEffect(() => {
    setCircleRadius(width * placeScale * 0.2);
    setLineWidth(width * placeScale * 0.04);
  }, [width, height, placeScale]);

  return (
    <>
      {tool === "SELECT.BOOK" && (
        <>
          {/* Sprite Object, rectangle of work places */}
          <Sprite
            data-testid="workplace-spr-rect"
            image={isColleaguesPlace ? WorkplaceRectForColleague : WorkplaceRect}
            cursor={isSelectable ? "pointer" : "auto"}
            position={{ x: 0 - (width * placeScale) / 2, y: -16 * placeScale }}
            width={width * placeScale}
            height={height * placeScale}
            filters={filterGroup}
          />
          {/* Sprite Object, circle of work places */}
          <Sprite
            data-testid="workplace-spr-circ"
            image={variantCircle(variant)}
            cursor={isSelectable ? "pointer" : "auto"}
            width={circleSize}
            height={circleSize}
            position={{ x: circlePos, y: circlePos - 16 * placeScale }}
            filters={filterGroup}
          />
        </>
      )}

      {tool !== "SELECT.BOOK" && (
        <>
          {/* Graphic objects, rectangle of places */}
          <Graphics
            data-testid="workplace-grp-rect"
            cursor={isSelectable ? "pointer" : "auto"}
            position={{ x: 0 - (width * placeScale) / 2, y: -16 * placeScale }}
            draw={g => {
              g.clear();
              g.beginFill(0xd2dae1, 1);
              g.drawRoundedRect(0, 0, width * placeScale, height * placeScale, 13 * placeScale);
              g.endFill();
            }}
            filters={filterGroup}
          />
          {/* Graphic objects, circle of places */}
          <Graphics
            data-testid="workplace-grp-circ"
            cursor={isSelectable ? "pointer" : "auto"}
            position={{ x: 0, y: -16 * placeScale }}
            filters={filterGroup}
            draw={g => {
              g.clear();
              g.lineStyle(lineWidth, 0xffffff); // prev: 6 * placeScale
              g.beginFill(variantColor, 1);
              g.drawCircle(0, 0, circleRadius); // prev : 30 * placeScale
              g.endFill();
            }}
          />
        </>
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import { Graphics, Sprite } from "@pixi/react";
import { ColorOverlayFilter } from "@pixi/filter-color-overlay";
import { ParkPlaceRect } from "./SpriteIndex";
import BoundingBox from "../../../Domain/Types/BoundingBox.type";
import { ToolSelection } from "../../Views/CreateFloorPlanView/CreateFloorPlanView";
import { PlaceVariant } from "../../../Domain/Types/FloorPlan/PlaceVariant.type";
import { variantCircle } from "./PlaceSprite.functions";

interface Props {
  placeTypeId: number;
  boundingBox: BoundingBox;
  filterGroup: ColorOverlayFilter[];
  variantColor: number;
  variant: PlaceVariant;
  placeScale: number;
  isSelectable: boolean;
  tool?: ToolSelection;
  icon?: string;
  isColleaguesPlace?: boolean;
}

/**
 * draw the place sprite, shared for the parking place and electric charging station
 * by pixi-sprite or pixi-graphic according to module(booking/creating)
 * @version 1.0.0
 */
export function ParkPlaceSprite({
  placeTypeId,
  boundingBox,
  filterGroup,
  variantColor,
  variant,
  placeScale,
  isSelectable,
  tool,
  isColleaguesPlace
}: Props) {
  const { width = 160, height = 80 } = boundingBox;

  const [circleRadius, setCircleRadius] = useState(width * placeScale * 0.2);
  const [lineWidth, setLineWidth] = useState(width * placeScale * 0.02);

  const circleSize = (width * placeScale) / 2.1;
  const circlePos = 0 - circleSize / 2;

  useEffect(() => {
    setCircleRadius(width * placeScale * 0.2);
    setLineWidth(width * placeScale * 0.02);
  }, [width, height, placeScale]);

  return (
    <>
      {tool === "SELECT.BOOK" && (
        <>
          {/* Sprite Object, rectangle of parking places */}
          <Sprite
            data-testid="parkingplace-spr-rect"
            image={spriteBorderColorPerType(placeTypeId, isColleaguesPlace)}
            position={{ x: 0 - (width * placeScale) / 2, y: 0 - (height * placeScale) / 2 }}
            width={width * placeScale}
            height={height * placeScale}
            filters={filterGroup}
            cursor={isSelectable ? "pointer" : "auto"}
          />
          {/* Sprite Object, circle of parking places */}
          <Sprite
            data-testid="parkingplace-spr-circ"
            image={variantCircle(variant)}
            width={circleSize}
            height={circleSize}
            position={{ x: circlePos, y: circlePos - (height * placeScale) / 4 }}
            filters={filterGroup}
            cursor={isSelectable ? "pointer" : "auto"}
          />
        </>
      )}
      {tool !== "SELECT.BOOK" && (
        <>
          {/* Graphic objects, rectangle of parking places */}
          <Graphics
            data-testid="parkingplace-grp-rect"
            cursor={isSelectable ? "pointer" : "auto"}
            filters={filterGroup}
            position={{ x: 0 - (width * placeScale) / 2, y: 0 - (height * placeScale) / 2 }}
            draw={g => {
              g.clear();
              g.lineStyle(5 * placeScale, borderColorPerType(placeTypeId));
              g.beginFill(0xd2dae1, 1);
              g.drawRect(0, 0, width * placeScale, height * placeScale);
              g.endFill();
            }}
          />
          {/* Graphic objects, circle of parking places */}
          <Graphics
            data-testid="parkingplace-grp-circ"
            cursor={isSelectable ? "pointer" : "auto"}
            filters={filterGroup}
            position={{ x: 0, y: 0 - (height * placeScale) / 4 }}
            draw={g => {
              g.clear();
              g.lineStyle(lineWidth, 0xffffff);
              g.beginFill(variantColor, 1);
              g.drawCircle(0, 0, circleRadius);
              g.endFill();
            }}
          />
        </>
      )}
    </>
  );
}

export function borderColorPerType(placeTypeId: number) {
  const parkingPlaceBorder = 0x0000ff;
  const electricChargingBorder = 0x75baff;

  switch (placeTypeId) {
    case 2:
      return parkingPlaceBorder;
    case 4:
      return electricChargingBorder;
    default:
      return parkingPlaceBorder;
  }
}
export function spriteBorderColorPerType(placeTypeId: number, isColleaguesPlace?: boolean) {
  switch (placeTypeId) {
    case 2:
      return isColleaguesPlace ? ParkPlaceRect.ParkingColleague : ParkPlaceRect.Parking;
    case 4:
      return isColleaguesPlace
        ? ParkPlaceRect.ElectricChargingColleague
        : ParkPlaceRect.ElectricCharging;
    default:
      return ParkPlaceRect.Parking;
  }
}
